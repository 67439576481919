import React, { Component, lazy, Suspense } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { Offline, Online } from "react-detect-offline";

import Offlinepage from "./components/Offlinepage.jsx";
import PageNotFound from "./components/PageNotFound";

const WelcomeScreen1 = lazy(() => import("./pages/WelcomeScreen1"));
const WelcomeScreen2 = lazy(() => import("./pages/WelcomeScreen2"));
const QrCodeInterface = lazy(() => import("./pages/QrCodeInterface"));
const Register = lazy(() => import("./pages/Register"));
const Login = lazy(() => import("./pages/Login"));
const HomePage = lazy(() => import("./pages/HomePage"));
const LaundryFaq = lazy(() => import("./pages/LaundryFaq"));
const MachineFaq = lazy(() => import("./pages/MachineFaq"));
// const UpdateProfile = lazy(() => import("./pages/UpdateProfile"));
const ContactUs = lazy(() => import("./pages/ContactUs"));
const ArticleDetails = lazy(() => import("./pages/ArticleDetails"));
const RecognizedSymbols = lazy(() => import("./pages/RecognizedSymbols"));
const ChangePassword = lazy(() => import("./pages/ChangePassword"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ChatToUs = lazy(() => import("./pages/ChatToUs"));

const PrivacyPolicies = lazy(() => import("./pages/CMS/PrivacyPolicies"))
const TermsAndConditions = lazy(() => import("./pages/CMS/TermsAndConditions"))

const ResetPassword = lazy(() => import("./pages/ResetPassword"))


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  Authorization = () => {
    return localStorage.getItem('token') ? true : false
  }
  render() {
    // console.log('  this.Authorization()',  this.Authorization());
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route {...rest} render={(props) =>
        (
          this.Authorization() ?
            <Component {...props} />
            : <Redirect to='/' />
        )} />
    );
    const LoginRoute = ({ component: Component, ...rest }) => (
      <Route {...rest} render={(props) =>
        (
          this.Authorization() ?
            <Redirect to='/homePage' />
            :  <Component {...props} />
        )} />
    );
    return (
      <Provider store={store}>
        <Offline>
          <Offlinepage />
        </Offline>
        <Online>
          <PersistGate loading={null} persistor={persistor}>
            <Router >
              <Suspense fallback={<div></div>}>
                <Switch>
                  <PrivateRoute exact path="/homepage" component={HomePage} />
                  <PrivateRoute exact path="/laundryFaqDetails" component={LaundryFaq} />
                  <PrivateRoute exact path="/machineFaqDetails" component={MachineFaq} />
                  <PrivateRoute exact path="/updateProfile" component={Register} />
                  <PrivateRoute exact path="/contactUs" component={ContactUs} />
                  <PrivateRoute exact path="/faqArticle" component={ArticleDetails} />
                  <PrivateRoute exact path="/scannedLabels" component={RecognizedSymbols} />
                  <PrivateRoute exact path="/changePassword" component={ChangePassword} />

                  <Route exact path="/" component={WelcomeScreen1} />
                  <Route exact path="/welcomeScreen2" component={WelcomeScreen2} />
                  <Route exact path="/scanQR" component={QrCodeInterface} />
                  <LoginRoute exact path="/register" component={Register} />
                  <LoginRoute exact path="/login" component={Login} />
                  <Route exact path="/forgotPassword" component={ForgotPassword} />
                  <Route exact path="/chatToUs" component={ChatToUs} />

                  <Route exact path="/Terms-Conditions" component={TermsAndConditions} />
                  <Route exact path="/Privacy-Policies" component={PrivacyPolicies} />

                  <Route exact path="/reset-password" component={ResetPassword} />

                  <Route path="/*" component={PageNotFound} />
                </Switch>
              </Suspense>
            </Router>
          </PersistGate>
        </Online>
      </Provider>
    );
  }
}

export default App;
