import React, { Component } from 'react';

class PageNotFound extends Component {
  render() {
    return (
      <div>
        <center><img src="/assets/images/pagenotfound.jpg" alt="" /></center>
        <button type="button" className="btn btn-primary text-center mt-5" style={{ marginLeft: "44%" }} onClick={() => this.props.history.push('/')}>BACK TO HOME</button>
      </div>
    );
  }
}

export default PageNotFound;