import React, { Component } from 'react';



class OfflinePage extends Component {
    state = {}
    render() {
        return (
            <div className="offline-outer offline-msg">
                <div className="offline-inner">
                    <img src="https://user-images.githubusercontent.com/24848110/33519396-7e56363c-d79d-11e7-969b-09782f5ccbab.png" alt="offline" title="offline" className="img-fluid" />
                    <h4>No network</h4>
                    {/* <p>No se encontró internet o conexión lenta.</p>
                    <p>Comprueba tu conexión o vuelve a intentarlo</p> */}
                    <button className="btn btn-primary" onClick={() => window.location.reload(true)}>reload</button>
                </div>
            </div>
        );
    }
}

export default OfflinePage;