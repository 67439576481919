const INTIAL_STATE = {
  loginData: {},
  signUpData:{},
  qr_data: {},
  washing_labels: {},
  user_details:{}

}
export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case 'ADMIN_LOGIN':
      return Object.assign({}, state, { loginData: action.payload.data })
    case 'SIGN_UP':
      return Object.assign({}, state, { signUpData: action.payload.data })
      case 'QR_INTERFACE':
        return Object.assign({}, state, { qr_data: action.payload.data })
      case 'QR_DETAILS':
      return Object.assign({}, state, { qr_data: {} })
      case 'USER_DETAILS':
        return Object.assign({}, state, { user_details: {} })
      case 'WASHING_LABEL':
        return Object.assign({}, state, { washing_labels: action.payload.data })
    case "LOGOUT":
      return INTIAL_STATE
    default:
      return state;
  }

}